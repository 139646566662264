var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('top'),_c('div',{staticStyle:{"width":"100%","height":"50px"}}),_c('el-carousel',{staticStyle:{"margin-bottom":"10px"},attrs:{"height":"98px"}},_vm._l((_vm.banner),function(item){return _c('el-carousel-item',{key:item},[_c('a',{attrs:{"href":"http://" + item.url}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.litpic,"alt":""}})])])}),1),_c('div',{staticClass:"mid2"},[_vm._l((_vm.arctype),function(item,index){return _c('el-select',{key:index,staticClass:"select",attrs:{"placeholder":item.typename},on:{"change":_vm.change},model:{value:(_vm.choose_type[index]),callback:function ($$v) {_vm.$set(_vm.choose_type, index, $$v)},expression:"choose_type[index]"}},[_c('el-option',{attrs:{"label":"全部","value":""}}),_vm._l((item.son),function(itemm,indexx){return _c('el-option',{key:indexx,attrs:{"label":itemm.typename,"value":itemm.id}})})],2)}),_c('el-select',{staticClass:"select",on:{"change":_vm.changemodel},model:{value:(_vm.show_model),callback:function ($$v) {_vm.show_model=$$v},expression:"show_model"}},[_c('el-option',{attrs:{"label":"图片模式","value":"1"}}),_c('el-option',{attrs:{"label":"样衣模式","value":"2"}})],1)],2),_c('div',{staticClass:"bottom2"},[_c('div',{staticClass:"bottomimg"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"bottomimg2",on:{"click":function($event){return _vm.go('Details', item.aid)}}},[(item.sales_num >= _vm.hot_limit)?_c('img',{staticClass:"poa",attrs:{"src":require("../../assets/bao.png"),"alt":""}}):_vm._e(),(_vm.show_model=='1')?_c('img',{attrs:{"src":_vm.imageUrl(item.litpic),"alt":""}}):_vm._e(),(_vm.show_model=='2')?_c('div',{staticClass:"yiwrap"},[(item.clothingTem)?_c('img',{staticClass:"closeimg",attrs:{"src":_vm.imageUrl(item.clothingTem),"alt":""}}):_vm._e(),(item.repeat == 1)?_c('div',{staticClass:"logo",style:({
		  	  'z-index':'1',
		  	  position: 'absolute', 
		  	  width: '100%',
		  	  height: '100%',
		  	  backgroundImage: 'url(' + _vm.imageUrl(item.imgs) + ')',
		  	  backgroundSize: '39px',
		  	}),attrs:{"id":'img'+item.aid}}):_vm._e(),(item.repeat != 1)?_c('img',{staticClass:"logo1",attrs:{"id":'img'+item.aid,"src":_vm.imageUrl(item.imgs),"alt":""}}):_vm._e(),_c('div',{staticClass:"bj",style:({ backgroundColor: item.color })})]):_vm._e()])}),0),_c('div',{staticClass:"page"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-size":_vm.size,"layout":"total, prev, pager, next ,jumper","pager-count":5,"total":_vm.count},on:{"current-change":_vm.handleCurrentChange}})],1)]),_c('info'),_c('div',{staticStyle:{"width":"100%","height":"60px","background-color":"#f6f6f6"}}),_c('foot')],1)}
var staticRenderFns = []

export { render, staticRenderFns }